<template>
	<div class="cafe_newtable" v-if="tables.length > 0">
		<div class="wrap_tit">
			<h3 class="tit_item">추천 테이블</h3>
		</div>
		<RecommendTable v-for="(table, i) in tables" :key="table.recommendTable.tableId" v-bind="table" v-show="i === currentIndex"/>
		<div class="wrap_btn">
			<button class="btn_navi btn_prev" :disabled="!hasPrev" @click="goToPrevPage" v-tiara="tiaraObj.recommendTablePrevBtn">
				<span class="ico_cafenew">이전 보기</span>
			</button>
			<button class="btn_navi btn_next" :disabled="!hasNext" @click="goToNextPage" v-tiara="tiaraObj.recommendTableNextBtn">
				<span class="ico_cafenew">다음 보기</span>
			</button>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import RecommendTable from './RecommendTable.vue';

export default {
	components: {RecommendTable},
	data() {
		return {
			currentIndex: 0,
		};
	},
	computed: {
		...mapGetters({
			tables: 'getRecommendTables',
			tiaraObj: 'getTiaraObj'
		}),
		hasPrev() {
			return this.currentIndex > 0;
		},
		hasNext() {
			return this.currentIndex < this.tables.length - 1;
		}
	},
	methods: {
		goToNextPage() {
			if (!this.hasNext) {
				return;
			}

			this.currentIndex += 1;
		},
		goToPrevPage() {
			if (!this.hasPrev) {
				return;
			}

			this.currentIndex -= 1;
		}
	},
	async created() {
		await this.$store.dispatch('loadRecommendTables')
	}
}
</script>
