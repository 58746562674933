var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "a",
      {
        staticClass: "link_favorite",
        attrs: { href: _vm.item.homeUrl, target: "_blank" },
      },
      [
        _c("div", { staticClass: "thumb_info_type" }, [
          _c("div", { staticClass: "wrap_thumb" }, [
            _c("img", {
              staticClass: "img_thumb",
              attrs: {
                src:
                  "//img1.daumcdn.net/thumb/C151x151/?fname=" +
                  _vm.item.thumbnail,
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wrap_info" }, [
            _c("div", { staticClass: "box_tbl" }, [
              _c("div", { staticClass: "inner_tbl" }, [
                _c("strong", { staticClass: "tit_info type_change_line" }, [
                  _vm._v(_vm._s(_vm.item.cafeName)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "txt_explanation" }, [
                  _vm._v(_vm._s(_vm.item.desc)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tag_type_info" },
                  [
                    _c("em", { staticClass: "txt_info txt_emph" }, [
                      _vm._v("#추천카페"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.keywords, function (keyword) {
                      return _c("span", { staticClass: "txt_info" }, [
                        _vm._v("#" + _vm._s(keyword)),
                      ])
                    }),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }