<template>
	<div id="daumContent" role="main">
		<home-recommend></home-recommend>
		<my-cafe></my-cafe>
		<home-main></home-main>
	</div>
</template>

<script>
	import HomeRecommend from './components/home/HomeRecommend.vue'
	import MyCafe from './components/home/MyCafe.vue'
	import HomeMain from './components/home/HomeMain.vue'

	export default {
		components: {
			HomeRecommend,
			MyCafe,
			HomeMain,
		},

		created() {
			this.$trackPage();
		},
	}
</script>
