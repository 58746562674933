<template>
	<li class="cafe_create">
		<a href="/_c21_/make" class="link_favorite">
			<div class="thumb_info_type">
				<div class="wrap_thumb"><img src="//t1.daumcdn.net/cafe_image/cafetop/2019/favorite_defalut.png"
											 class="img_thumb" alt=""></div>
				<div class="wrap_info">
					<div class="box_tbl">
						<div class="inner_tbl">
							<strong class="tit_info">카페 만들기</strong>
							<div class="additional_info"><span class="txt_item">지금 카페를 만들어 보세요!</span></div>
						</div>
					</div>
				</div>
			</div>
		</a>
	</li>
</template>

<script>
	export default {}
</script>
