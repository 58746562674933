var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cafe_slider", attrs: { id: "mFeature" } }, [
    _c("h3", { staticClass: "screen_out" }, [_vm._v("카페 인기글")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list_item" },
      [
        _vm.banner
          ? _c("recommend-banner", {
              directives: [
                {
                  name: "tiara",
                  rawName: "v-tiara",
                  value: _vm.tiaraObj.sliderBanner,
                  expression: "tiaraObj.sliderBanner",
                },
              ],
              attrs: { banner: _vm.banner },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.contents, function (item, i) {
          return _c("recommend-list", { attrs: { item: item, i: i } })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bg_slider" }, [
      _c("img", {
        staticClass: "img_slider_bnr",
        attrs: {
          src: "//t1.daumcdn.net/cafe_image/cafetop/2019/bg_feature.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn_slider btn_prev", attrs: { type: "button" } },
      [
        _c("span", { staticClass: "ico_cafenew" }, [
          _vm._v("슬라이드 앞으로 이동"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      { staticClass: "btn_slider btn_next", attrs: { type: "button" } },
      [
        _c("span", { staticClass: "ico_cafenew" }, [
          _vm._v("슬라이드 뒤로 이동"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }