var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "a",
      {
        staticClass: "link_rank_top",
        attrs: { href: _vm.item.cafeUrl, target: "_blank" },
      },
      [
        _c(
          "div",
          { staticClass: "item_fancafe_top" },
          [
            _c("rank-updown", { attrs: { item: _vm.item } }),
            _vm._v(" "),
            _c("div", { staticClass: "thumb_g" }, [
              _c("img", {
                attrs: {
                  src:
                    "//img1.daumcdn.net/thumb/S700x420/?fname=" +
                    _vm.item.topRankImageUrl,
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "thumb_info_type" }, [
              _c("div", { staticClass: "wrap_thumb" }, [
                _c("img", {
                  staticClass: "img_thumb",
                  attrs: { src: _vm.item.profileImageUrl, alt: "" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "wrap_info" }, [
                _c("div", { staticClass: "box_tbl" }, [
                  _c("div", { staticClass: "inner_tbl" }, [
                    _c("strong", { staticClass: "tit_info" }, [
                      _vm._v(_vm._s(_vm.item.starName)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tag_type_info" }, [
                      _c("span", { staticClass: "txt_info txt_cafe" }, [
                        _vm._v("카페 "),
                        _c("em", [
                          _vm._v(_vm._s(_vm.item.cafeCurrRank) + "등"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "txt_info txt_cheer" }, [
                        _vm._v("응원 "),
                        _c("em", [
                          _vm._v(_vm._s(_vm.item.cheerCurrRank) + "등"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }