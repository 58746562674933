<template>
	<div id="cSub">
		<div class="mycafe_favorite">
			<div class="inner_mycafe">
				<h3 class="screen_out">내카페리스트 및 즐겨찾는 게시판리스트</h3>
				<div class="cafe_util">
					<div v-if="status === 'loggedOut'" class="login_info">
						<p class="txt_util">지금 로그인하고 다양한 카페를 찾아 가입해 보세요!</p>
						<a :href="loginUrl" class="link_login">로그인하기</a>
					</div>
					<div v-else class="wrap_menu">
						<ul class="list_menu">
							<li :class="myCafeTab"><a @click="loadCafe" class="link_item">내 카페<span class="num_item">{{myCafeCount}}</span></a></li>
							<li :class="myBoardTab">
								<a @click="loadBoard" class="link_item">즐겨찾는 게시판<span class="num_item">{{myBoardCount}}</span></a>
								<div class="msgbox">
									<p>즐겨찾는 게시판이 없습니다.</p>
									<p>모바일 웹과 카페 앱에서 내카페의 즐겨찾는 게시판을 등록해 보세요.</p>
								</div>
							</li>
						</ul>
						<div class="wrap_inform">
							<a v-tiara="tiaraObj.myNewsBtn" href="/_c21_/my_news" class="link_item">알림<span v-if="hasNewNews" class="inform_circle">새 알림 도착</span></a>
							<a v-tiara="tiaraObj.messageBtn" href="/_c21_/my_message" class="link_item">쪽지<span v-if="hasNewMessage" class="inform_circle">새 쪽지 도착</span></a>
						</div>
						<div v-if="myCafeCount === 0" class="no_mycafe">
							<p class="txt_util">가입한 카페가 없습니다. 다양한 카페를 찾아 가입해보세요.</p>
						</div>
					</div>
				</div>
				<div id="home_top" class="card_favorite">
					<ul class="list_favorite">
						<template v-if="this.status === this.loggedOut">
							<suggest-cafe-item v-tiara="tiaraObj.guestCafeSuggest" v-for="item in suggestCafes" :item="item"></suggest-cafe-item>
						</template>
						<template v-else-if="status === myCafe">
							<favorite-cafe-item v-for="(item, i) in myCafes" :item="item" :i="i" @toggleMyCafe="toggleMyCafe"></favorite-cafe-item>
							<template v-if="myCafeCount < 1">
								<suggest-cafe-item v-tiara="tiaraObj.userCafeSuggest" v-for="item in suggestCafes" :item="item"></suggest-cafe-item>
							</template>
						</template>
						<template v-else-if="status === myBoard">
							<favorite-bbs-item v-tiara="tiaraObj.favBoard" v-for="(item, i) in myBoards.slice(0, 12)" :item="item" :i="i" @deleteBoard="deleteBoard"></favorite-bbs-item>
						</template>
						<make-cafe v-tiara="tiaraObj.cafeMakeBtn" v-if="status !== myBoard"></make-cafe>
					</ul>
					<a v-if="showLinkAll" :href="goMyCafe" class="link_all" @click="trackMoreBtnEvent">전체보기</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import SuggestCafeItem from './SuggestCafeItem.vue'
	import FavoriteCafeItem from './FavoriteCafeItem.vue'
	import FavoriteBbsItem from './FavoriteBbsItem.vue'
	import MakeCafe from './MakeCafe.vue'

	export default {
		components: {
			SuggestCafeItem,
			FavoriteCafeItem,
			FavoriteBbsItem,
			MakeCafe,
		},
		data() {
			return {
				loggedOut: 'loggedOut',
				myCafe: 'myCafe',
				myBoard: 'myBoard',
				status: this.myCafe,
			}
		},
		computed: {
			...mapGetters({
				popularlist: 'getPopularlist',
				date: 'getDate',
				time: 'getTime',
				banner: 'getCategoryBanner',
				contents: 'getCategoryContents',
				suggestCafes: 'suggestCafe/getSuggestCafes',
				myCafes: 'getMyCafe',
				myBoards: 'getMyBoard',
				myCafeCount: 'getMyCafeCount',
				myBoardCount: 'getMyBoardCount',
				hasNewNews: 'getNewNews',
				hasNewMessage: 'getNewMessage',
				tiaraObj: 'getTiaraObj',
			}),
			goMyCafe() {
				let link = '/_c21_/my_cafe#'; 
				if (this.status === this.myCafe) return link + 'mycafe';
				else if (this.status === this.myBoard) return link + 'myboard';
			},
			myCafeTab() {
				return {on: this.status === this.myCafe}
			},
			myBoardTab() {
				return {
					on: this.status === this.myBoard,
					empty: this.myBoardCount === 0
				}
			},
			loginUrl(){
				return 'https://logins.daum.net/accounts/loginform.do?url=' + window.location.href
			},
			showLinkAll(){
				return (this.status === this.myCafe && this.myCafeCount > 12) || (this.status === this.myBoard && this.myBoardCount > 12)
			},
		},
		methods: {
			async loadCafe() {
				this.status = this.myCafe;
				await this.$store.dispatch('loadMyCafe')
				if(this.myCafeCount < 1)
					this.loadSuggest()
			},
			async loadBoard() {
				if(this.myBoardCount < 1){
					return false;
				}
				this.status = this.myBoard
				this.$store.dispatch('loadMyBoard')
			},
			loadSuggest(){
				this.$store.dispatch('suggestCafe/loadSuggestCafes');
			},
			loadCounts(){
				this.$store.dispatch('loadMyCafeCount')
				this.$store.dispatch('loadMyBoardCount')
			},
			loadNews(){
				this.$store.dispatch('loadNewNews')
				this.$store.dispatch('loadNewMessage')
			},
			toggleMyCafe(i) {
				if(this.myCafes[i].favoriteCafe) {
					this.deleteMyCafe(i);
				} else {
					this.addMyCafe(i);
				}
			},
			async deleteMyCafe(i){
				await this.$store.dispatch('deleteFavoriteCafe', this.myCafes[i])
				this.myCafes[i].favoriteCafe = false;
			},
			async addMyCafe(i){
				await this.$store.dispatch('addFavoriteCafe', this.myCafes[i])
				this.myCafes[i].favoriteCafe = true;
			},
			async deleteBoard(i) {
				if(confirm('해제 시 새글알림도 받아볼 수 없습니다.')) {
					await this.$store.dispatch('deleteFavoriteBoard', this.myBoards[i])
					this.myBoards.splice(i, 1)
					if(this.myBoards.length === 12){
						this.loadBoard()
					}
				}
			},

			trackMoreBtnEvent() {
				this.$trackEvent(this.status === this.myCafe ? this.tiaraObj.myCafeMoreBtn : this.tiaraObj.favBoardMoreBtn);
			}
		},
		created() {
			if (IS_LOGIN) {
				this.loadCafe()
				this.loadNews()
				this.loadCounts()
			} else {
				this.status = this.loggedOut
				this.loadSuggest();
			}
		}
	}
</script>
