<template>
	<div class="cafe_notice">
		<div class="wrap_tit"><h3 class="tit_item">공지</h3></div>
		<a v-tiara="tiaraObj.supportersNoti" v-if="i === 0" v-for="(item, i) in list" :href="list[0].link" target="_blank" class="link_important"><strong class="notice_important">{{list[0].title}}<span v-if="list[0].new" class="txt_new">NEW</span></strong></a>
		<ul class="list_notice">
			<li v-for="item in list.slice(1)">
				<a v-tiara="tiaraObj.supportersNoti"
					:href="item.link" target="_blank" class="link_notice">{{item.title}}<span v-if="list.new" class="txt_new">NEW</span></a>
			</li>
		</ul>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	export default {
		computed:{
			...mapGetters({
				list: 'getNotice',
				tiaraObj: 'getTiaraObj',
			}),
		},
		async created() {
			await this.$store.dispatch('loadNotice')
		}
	}
</script>
