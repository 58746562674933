<template>
	<li>
		<div class="box_slider slider_box_type">
			<span class="txt_story">이야기 {{count}} <em v-html="item.category.name"></em></span>
			<div class="text_cont">
				<recommend-list-item v-for="(cafe, i) in item.articles.slice(0, 4)" v-tiara="recommendTiaraObject(cafe)" :cafe="cafe" :i="i" :num="num" @hoverEvent="changeNum"></recommend-list-item>
			</div>
		</div>
	</li>
</template>

<script>
	import { mapGetters } from 'vuex';
	import RecommendListItem from './RecommendListItem.vue'
	export default {
		components:{
			RecommendListItem
		},
		props: ['item', 'i'],
		data(){
			return {
				num: -1,
				countWords: ['하나', '둘', '셋', '넷', '다섯', '여섯', '일곱','여덟', '아홉','열']
			}
		},
		computed: {
			...mapGetters({
				tiaraObj: 'getTiaraObj',
			}),
			randomNumber(){
				return Math.floor(Math.random() * Math.floor(3));
			},
			count(){
				return this.countWords[this.i]
			}
		},
		methods: {
			changeNum(num){
				this.num = num;
			},
			recommendTiaraObject(cafe) {
				let cloneBase = JSON.parse(JSON.stringify(this.tiaraObj.sliderArticle));
				cloneBase.customProps.category = this.item.category.name;
				cloneBase.customProps.title = cafe.content.title;
				return cloneBase;
			}
		},
		created(){
			this.num = this.randomNumber; 
		}
	}
</script>
