var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cafe_notice" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.list, function (item, i) {
        return i === 0
          ? _c(
              "a",
              {
                directives: [
                  {
                    name: "tiara",
                    rawName: "v-tiara",
                    value: _vm.tiaraObj.supportersNoti,
                    expression: "tiaraObj.supportersNoti",
                  },
                ],
                staticClass: "link_important",
                attrs: { href: _vm.list[0].link, target: "_blank" },
              },
              [
                _c("strong", { staticClass: "notice_important" }, [
                  _vm._v(_vm._s(_vm.list[0].title)),
                  _vm.list[0].new
                    ? _c("span", { staticClass: "txt_new" }, [_vm._v("NEW")])
                    : _vm._e(),
                ]),
              ]
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list_notice" },
        _vm._l(_vm.list.slice(1), function (item) {
          return _c("li", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tiara",
                    rawName: "v-tiara",
                    value: _vm.tiaraObj.supportersNoti,
                    expression: "tiaraObj.supportersNoti",
                  },
                ],
                staticClass: "link_notice",
                attrs: { href: item.link, target: "_blank" },
              },
              [
                _vm._v(_vm._s(item.title)),
                _vm.list.new
                  ? _c("span", { staticClass: "txt_new" }, [_vm._v("NEW")])
                  : _vm._e(),
              ]
            ),
          ])
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap_tit" }, [
      _c("h3", { staticClass: "tit_item" }, [_vm._v("공지")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }