<template>
	<li>
		<a :href="item.readUrl" target="_blank" class="link_popular">
			<div class="thumb_info_type">
				<div class="wrap_thumb">
					<img :src="popularThumnail" class="img_thumb" alt="">
				</div>
				<div class="wrap_info">
					<span class="num_item">{{item.rank}}</span>
					<p class="desc_info">{{item.title}}</p>
				</div>
			</div>
			<div class="additional_info">
				<span class="txt_item">{{item.cafeName}}</span>
				<span class="txt_comment">댓글 <span class="num_comment">{{item.commentCount}}</span></span>
			</div>
		</a>
	</li>
</template>

<script>
	export default {
		props: ['item'],
		computed: {
			popularThumnail() {
				if(this.item.thumbnail)
					return '//img1.daumcdn.net/thumb/S104x65/?fname=' + this.item.thumbnail
				else
					return '//t1.daumcdn.net/cafe_image/cafetop/2024/noimage_hot.png'
			}
		}
	}
</script>
