<template>
	<div class="cafe_item" :class="ison" @mouseover="activate">
		<a :href="cafe.content.readUrl" target="_blank">
			<strong class="tit_info"><span class="screen_out">글제목</span><span v-html="cafe.content.title"></span></strong>
			<span class="txt_name"><span class="screen_out">카페명</span><span v-html="cafe.cafeName"></span></span>
		</a>
	</div>
</template>

<script>
	export default {
		props: ['cafe', 'i', 'num'],
		computed: {
			ison(){
				return { hover_effect: this.i === this.num }
			}
		},
		methods: {
			activate(){
				this.$emit('hoverEvent', this.i)
			}
		},
	}
</script>
