var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c("div", { staticClass: "box_slider slider_box_type" }, [
      _c("span", { staticClass: "txt_story" }, [
        _vm._v("이야기 " + _vm._s(_vm.count) + " "),
        _c("em", { domProps: { innerHTML: _vm._s(_vm.item.category.name) } }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text_cont" },
        _vm._l(_vm.item.articles.slice(0, 4), function (cafe, i) {
          return _c("recommend-list-item", {
            directives: [
              {
                name: "tiara",
                rawName: "v-tiara",
                value: _vm.recommendTiaraObject(cafe),
                expression: "recommendTiaraObject(cafe)",
              },
            ],
            attrs: { cafe: cafe, i: i, num: _vm.num },
            on: { hoverEvent: _vm.changeNum },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }