<template>
	<li>
		<a :href="item.cafeUrl" target="_blank" class="link_rank_top">
			<div class="item_fancafe_top">
				<rank-updown :item="item"></rank-updown>
				<div class="thumb_g"><img :src="'//img1.daumcdn.net/thumb/S700x420/?fname=' + item.topRankImageUrl" alt=""></div>
				<div class="thumb_info_type">
					<div class="wrap_thumb"><img :src="item.profileImageUrl" class="img_thumb" alt=""></div>
					<div class="wrap_info">
						<div class="box_tbl">
							<div class="inner_tbl">
								<strong class="tit_info">{{item.starName}}</strong>
								<div class="tag_type_info">
									<span class="txt_info txt_cafe">카페 <em>{{item.cafeCurrRank}}등</em></span>
									<span class="txt_info txt_cheer">응원 <em>{{item.cheerCurrRank}}등</em></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a>
	</li>
</template>

<script>
	import RankUpdown from './RankUpdown.vue'
	export default {
		components: {
			RankUpdown,
		},
		props: ['item'],
		
	}
</script>
