<template>

	<div id="mFeature" class="cafe_slider">
		<h3 class="screen_out">카페 인기글</h3>
		<div class="bg_slider"><img src="//t1.daumcdn.net/cafe_image/cafetop/2019/bg_feature.png" class="img_slider_bnr" alt=""></div>
		
		<button type="button" class="btn_slider btn_prev"><span class="ico_cafenew">슬라이드 앞으로 이동</span></button>
		<button type="button" class="btn_slider btn_next"><span class="ico_cafenew">슬라이드 뒤로 이동</span></button>

		<ul class="list_item">
			<recommend-banner v-tiara="tiaraObj.sliderBanner" v-if="banner" :banner="banner"></recommend-banner>
			<recommend-list v-for="(item, i) in contents" :item="item" :i="i"></recommend-list>
		</ul>
	</div>

</template>

<script>
	import {mapGetters} from 'vuex';
	import RecommendBanner from './RecommendBanner.vue'
	import RecommendBest from './RecommendBest.vue'
	import RecommendList from './RecommendList.vue'

	export default {
		components: {
			RecommendBanner,
			RecommendBest,
			RecommendList
		},
		computed: {
			...mapGetters({
				banner: 'getCategoryBanner',
				contents: 'getCategoryContents',
				tiaraObj: 'getTiaraObj',
			}),
		},
		methods:{
		},
		async created() {
			await this.$store.dispatch('loadCategoryArticle')
			cafeHomeSlider.init()
		}
	}
</script>
