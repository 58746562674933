var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "num_item" }, [
    _vm._v("\n\t" + _vm._s(_vm.item.fandomCurrRank) + "\n\t"),
    _vm.rankChange > 0
      ? _c("span", { staticClass: "info_change" }, [
          _c("span", { staticClass: "ico_cafetop ico_up" }, [_vm._v("상승")]),
          _vm._v(_vm._s(_vm.rankChange)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.rankChange < 0
      ? _c("span", { staticClass: "info_change" }, [
          _c("span", { staticClass: "ico_cafetop ico_down" }, [_vm._v("하락")]),
          _vm._v(_vm._s(Math.abs(_vm.rankChange))),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.rankChange === 0
      ? _c("span", { staticClass: "info_change" }, [
          _c("span", { staticClass: "ico_cafetop ico_stay" }, [_vm._v("유지")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }