var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c("div", { staticClass: "slider_image_wrapper" }, [
      _c("div", { staticClass: "slider_image_type" }, [
        _c("a", { attrs: { href: _vm.banner.link, target: "_blank" } }, [
          _c("img", {
            staticClass: "img_slider",
            attrs: { src: _vm.banner.image, alt: _vm.banner.content },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "screen_out" }, [
          _vm._v("인기글 슬라이더 배너"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }