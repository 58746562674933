var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cont_newtable" }, [
    _c("div", { staticClass: "item_first" }, [
      _c(
        "a",
        {
          directives: [
            {
              name: "tiara",
              rawName: "v-tiara",
              value: _vm.tiaraObj.recommendTableTitle,
              expression: "tiaraObj.recommendTableTitle",
            },
          ],
          staticClass: "link_first",
          attrs: { href: _vm.tableHomeUrl, target: "_blank" },
        },
        [
          _c("div", { staticClass: "wrap_thumb" }, [
            _c("img", {
              staticClass: "img_thumb",
              attrs: {
                width: "80",
                height: "80",
                alt: "테이블 이미지",
                src: _vm.getThumbnail(_vm.recommendTable.imageUrl, "C160x160"),
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item_detail" }, [
            _c("strong", { staticClass: "tit_item" }, [
              _vm._v(_vm._s(_vm.recommendTable.name)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc_item" }, [
              _vm._v(_vm._s(_vm.recommendTable.introduction)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "info_item" }, [
              _c("span", { staticClass: "txt_info" }, [
                _vm._v(
                  "손님 " +
                    _vm._s(_vm.formatCounts(_vm.recommendTable.guestCount))
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "txt_info" }, [
                _vm._v(
                  "단골 " + _vm._s(_vm.formatCounts(_vm.totalCounts.favorite))
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "txt_info" }, [
                _vm._v("글 " + _vm._s(_vm.formatCounts(_vm.totalCounts.post))),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          directives: [
            {
              name: "tiara",
              rawName: "v-tiara",
              value: _vm.tiaraObj.recommendTableCategory,
              expression: "tiaraObj.recommendTableCategory",
            },
          ],
          staticClass: "link_more",
          attrs: { href: _vm.tableExplorerUrl, target: "_blank" },
        },
        [_vm._v(_vm._s(_vm.category.name) + " 테이블 더보기")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "list_table" },
      _vm._l(_vm.posts, function (post, index) {
        return _c("li", { key: post.postId }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "tiara",
                  rawName: "v-tiara",
                  value: _vm.tiaraObj.recommendTablePost,
                  expression: "tiaraObj.recommendTablePost",
                },
                {
                  name: "tiara-user-action",
                  rawName: "v-tiara-user-action",
                  value: _vm.getPostUserAction(post, index),
                  expression: "getPostUserAction(post, index)",
                },
                {
                  name: "tiara-view-imp",
                  rawName: "v-tiara-view-imp",
                  value: _vm.getViewImpContent(post, index),
                  expression: "getViewImpContent(post, index)",
                },
              ],
              staticClass: "link_table",
              attrs: {
                href: _vm.getTablePostUrl(post.tableId, post.postId),
                target: "_blank",
                "data-view-imp-id": post.postId,
              },
            },
            [
              _c("div", { staticClass: "wrap_thumb" }, [
                _c("img", {
                  staticClass: "img_thumb",
                  attrs: {
                    width: "160",
                    height: "90",
                    alt: "게시글 대표 이미지",
                    src: _vm.getThumbnail(
                      post.contentSummary.representationImage,
                      "C320x180"
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("strong", { staticClass: "tit_table" }, [
                _vm._v(_vm._s(post.title)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "desc_table" }, [
                _vm._v(_vm._s(post.contentSummary.content)),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }