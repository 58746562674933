var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "a",
      {
        staticClass: "link_popular",
        attrs: { href: _vm.item.readUrl, target: "_blank" },
      },
      [
        _c("div", { staticClass: "thumb_info_type" }, [
          _c("div", { staticClass: "wrap_thumb" }, [
            _c("img", {
              staticClass: "img_thumb",
              attrs: { src: _vm.popularThumnail, alt: "" },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wrap_info" }, [
            _c("span", { staticClass: "num_item" }, [
              _vm._v(_vm._s(_vm.item.rank)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc_info" }, [
              _vm._v(_vm._s(_vm.item.title)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "additional_info" }, [
          _c("span", { staticClass: "txt_item" }, [
            _vm._v(_vm._s(_vm.item.cafeName)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "txt_comment" }, [
            _vm._v("댓글 "),
            _c("span", { staticClass: "num_comment" }, [
              _vm._v(_vm._s(_vm.item.commentCount)),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }