<template>
	<li class="popular_ad" :adfit-unit-id="adunit" :key="page">
		<a adfit-landing-url class="link_popular">
			<div class="thumb_info_type">
				<div class="wrap_thumb">
					<img adfit-image-width="104" adfit-image-height="65" adfit-main-img-url="" class="img_thumb" alt="">
				</div>
				<div class="wrap_info">
					<span class="num_item"><span class="ad_bar"></span></span>
					<p class="desc_info" adfit-title></p>
				</div>
			</div>
			<div class="additional_info">
				<div class="wrap_ad">
					<span class="ad_name"><span class="ico_cafenew ico_ad">광고</span><span adfit-profile-name=""></span></span>
				</div>
			</div>
		</a>
		<a adfit-ad-info style="display: none;"></a>
	</li>
</template>

<script>
	export default {
		props:['page'],
		computed:{
			adunit(){
				return IS_DEV ? 'DAN-IqcaWN3ALugiGEJL' : 'DAN-cZdJrJdvdMHhD5ZY' 
			}
		}
	}
</script>
