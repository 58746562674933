<template>
	<li>
		<a :href="item.link" target="_blank" class="link_favorite">
			<div class="thumb_info_type">
				<div class="wrap_thumb"><img :src="'//img1.daumcdn.net/thumb/C151x151/?fname=' + item.cafeImage" class="img_thumb" alt=""></div>
				<div class="wrap_info">
					<div class="box_tbl">
						<div class="inner_tbl">
							<strong class="tit_info">{{item.fldName}}</strong>
							<div class="additional_info">
								<span class="txt_item">{{item.cafeName}}</span>
								<span v-if="item.recentArticle" class="txt_date">{{createdWhen}}</span>
								<span v-if="item.recentArticle.new" class="txt_new">NEW</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</a>
		<button class="btn_favorite on_favorite" @click="deleteBoard" aria-pressed="true">
			<span class="ico_cafenew ico_favorite">즐겨찾기</span>
		</button>
	</li>
</template>

<script>
import moment from 'moment';

export default {
  props: ['item', 'i'],
  computed: {
    createdWhen() {
      if (this.item.recentArticle.createdAt)
        return moment(this.item.recentArticle.createdAt, 'YYYY-MM-DD+-HH:mm').format('YY.MM.DD.');
      return '';
    }
  },
  methods: {
    deleteBoard() {
      this.$emit('deleteBoard', this.i)
    }
  }
}
</script>
