var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cafe_support" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "wrap_support",
        on: { mouseover: _vm.offTimer, mouseleave: _vm.onTimer },
      },
      [
        _c(
          "ul",
          { staticClass: "list_support" },
          _vm._l(_vm.supporters, function (banner, i) {
            return _vm.activeIndex === i
              ? _c("li", { key: i }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tiara",
                          rawName: "v-tiara",
                          value: _vm.tiaraObj.supportersBanner,
                          expression: "tiaraObj.supportersBanner",
                        },
                      ],
                      staticClass: "link_support",
                      attrs: { href: banner.linkUrl, target: "_blank" },
                    },
                    [
                      _c("img", {
                        staticClass: "img_thumb",
                        attrs: { src: banner.thumb, alt: banner.title },
                      }),
                    ]
                  ),
                ])
              : _vm._e()
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "dotted_paging" },
          _vm._l(_vm.supporters, function (item, index) {
            return _c(
              "button",
              {
                staticClass: "dotted_item",
                class: _vm.isOn(index),
                on: {
                  click: function ($event) {
                    return _vm.changeBanner(index)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.totalBanners) +
                    "개 중 " +
                    _vm._s(index + 1) +
                    "번째 배너"
                ),
              ]
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap_tit" }, [
      _c("h3", { staticClass: "tit_item" }, [_vm._v("카페 지원 안내")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }