var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "cSub" } }, [
    _c("div", { staticClass: "mycafe_favorite" }, [
      _c("div", { staticClass: "inner_mycafe" }, [
        _c("h3", { staticClass: "screen_out" }, [
          _vm._v("내카페리스트 및 즐겨찾는 게시판리스트"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cafe_util" }, [
          _vm.status === "loggedOut"
            ? _c("div", { staticClass: "login_info" }, [
                _c("p", { staticClass: "txt_util" }, [
                  _vm._v("지금 로그인하고 다양한 카페를 찾아 가입해 보세요!"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "link_login", attrs: { href: _vm.loginUrl } },
                  [_vm._v("로그인하기")]
                ),
              ])
            : _c("div", { staticClass: "wrap_menu" }, [
                _c("ul", { staticClass: "list_menu" }, [
                  _c("li", { class: _vm.myCafeTab }, [
                    _c(
                      "a",
                      { staticClass: "link_item", on: { click: _vm.loadCafe } },
                      [
                        _vm._v("내 카페"),
                        _c("span", { staticClass: "num_item" }, [
                          _vm._v(_vm._s(_vm.myCafeCount)),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { class: _vm.myBoardTab }, [
                    _c(
                      "a",
                      {
                        staticClass: "link_item",
                        on: { click: _vm.loadBoard },
                      },
                      [
                        _vm._v("즐겨찾는 게시판"),
                        _c("span", { staticClass: "num_item" }, [
                          _vm._v(_vm._s(_vm.myBoardCount)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(0),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "wrap_inform" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tiara",
                          rawName: "v-tiara",
                          value: _vm.tiaraObj.myNewsBtn,
                          expression: "tiaraObj.myNewsBtn",
                        },
                      ],
                      staticClass: "link_item",
                      attrs: { href: "/_c21_/my_news" },
                    },
                    [
                      _vm._v("알림"),
                      _vm.hasNewNews
                        ? _c("span", { staticClass: "inform_circle" }, [
                            _vm._v("새 알림 도착"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tiara",
                          rawName: "v-tiara",
                          value: _vm.tiaraObj.messageBtn,
                          expression: "tiaraObj.messageBtn",
                        },
                      ],
                      staticClass: "link_item",
                      attrs: { href: "/_c21_/my_message" },
                    },
                    [
                      _vm._v("쪽지"),
                      _vm.hasNewMessage
                        ? _c("span", { staticClass: "inform_circle" }, [
                            _vm._v("새 쪽지 도착"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.myCafeCount === 0
                  ? _c("div", { staticClass: "no_mycafe" }, [
                      _c("p", { staticClass: "txt_util" }, [
                        _vm._v(
                          "가입한 카페가 없습니다. 다양한 카페를 찾아 가입해보세요."
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card_favorite", attrs: { id: "home_top" } }, [
          _c(
            "ul",
            { staticClass: "list_favorite" },
            [
              this.status === this.loggedOut
                ? _vm._l(_vm.suggestCafes, function (item) {
                    return _c("suggest-cafe-item", {
                      directives: [
                        {
                          name: "tiara",
                          rawName: "v-tiara",
                          value: _vm.tiaraObj.guestCafeSuggest,
                          expression: "tiaraObj.guestCafeSuggest",
                        },
                      ],
                      attrs: { item: item },
                    })
                  })
                : _vm.status === _vm.myCafe
                ? [
                    _vm._l(_vm.myCafes, function (item, i) {
                      return _c("favorite-cafe-item", {
                        attrs: { item: item, i: i },
                        on: { toggleMyCafe: _vm.toggleMyCafe },
                      })
                    }),
                    _vm._v(" "),
                    _vm.myCafeCount < 1
                      ? _vm._l(_vm.suggestCafes, function (item) {
                          return _c("suggest-cafe-item", {
                            directives: [
                              {
                                name: "tiara",
                                rawName: "v-tiara",
                                value: _vm.tiaraObj.userCafeSuggest,
                                expression: "tiaraObj.userCafeSuggest",
                              },
                            ],
                            attrs: { item: item },
                          })
                        })
                      : _vm._e(),
                  ]
                : _vm.status === _vm.myBoard
                ? _vm._l(_vm.myBoards.slice(0, 12), function (item, i) {
                    return _c("favorite-bbs-item", {
                      directives: [
                        {
                          name: "tiara",
                          rawName: "v-tiara",
                          value: _vm.tiaraObj.favBoard,
                          expression: "tiaraObj.favBoard",
                        },
                      ],
                      attrs: { item: item, i: i },
                      on: { deleteBoard: _vm.deleteBoard },
                    })
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.status !== _vm.myBoard
                ? _c("make-cafe", {
                    directives: [
                      {
                        name: "tiara",
                        rawName: "v-tiara",
                        value: _vm.tiaraObj.cafeMakeBtn,
                        expression: "tiaraObj.cafeMakeBtn",
                      },
                    ],
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.showLinkAll
            ? _c(
                "a",
                {
                  staticClass: "link_all",
                  attrs: { href: _vm.goMyCafe },
                  on: { click: _vm.trackMoreBtnEvent },
                },
                [_vm._v("전체보기")]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "msgbox" }, [
      _c("p", [_vm._v("즐겨찾는 게시판이 없습니다.")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "모바일 웹과 카페 앱에서 내카페의 즐겨찾는 게시판을 등록해 보세요."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }