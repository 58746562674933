var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "cMain" } },
    [
      _c("recommend-tables"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cafe_newbody" },
        [
          _c("popular-list"),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "mAside" } },
            [_c("cafe-notice"), _vm._v(" "), _c("cafe-support")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hotIssueBanner && _vm.hotIssueBanner.image
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tiara",
                  rawName: "v-tiara",
                  value: _vm.tiaraObj.banner,
                  expression: "tiaraObj.banner",
                },
              ],
              staticClass: "hot_issue_banner",
            },
            [
              _c(
                "a",
                { attrs: { href: _vm.hotIssueBanner.link, target: "_blank" } },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.hotIssueBanner.image,
                      alt: "인기글 하단 배너",
                      width: "1100",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("cafe-fancafe"),
      _vm._v(" "),
      _c("cafe-category"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }