<template>
	<div class="cont_newtable">
		<div class="item_first">
			<a class="link_first" :href="tableHomeUrl" target="_blank" v-tiara="tiaraObj.recommendTableTitle">
				<div class="wrap_thumb">
					<img class="img_thumb" width="80" height="80" alt="테이블 이미지" :src="getThumbnail(recommendTable.imageUrl, 'C160x160')"/>
				</div>
				<div class="item_detail">
					<strong class="tit_item">{{ recommendTable.name }}</strong>
					<p class="desc_item">{{ recommendTable.introduction }}</p>
					<div class="info_item">
						<span class="txt_info">손님 {{ formatCounts(recommendTable.guestCount) }}</span>
						<span class="txt_info">단골 {{ formatCounts(totalCounts.favorite) }}</span>
						<span class="txt_info">글 {{ formatCounts(totalCounts.post) }}</span>
					</div>
				</div>
			</a>
			<a class="link_more" :href="tableExplorerUrl" target="_blank" v-tiara="tiaraObj.recommendTableCategory">{{ category.name }} 테이블 더보기</a>
		</div>
		<ul class="list_table">
			<li v-for="(post, index) in posts" :key="post.postId">
				<a class="link_table" :href="getTablePostUrl(post.tableId, post.postId)" target="_blank" v-tiara="tiaraObj.recommendTablePost"
				   v-tiara-user-action="getPostUserAction(post, index)" v-tiara-view-imp="getViewImpContent(post, index)"
				   :data-view-imp-id="post.postId">
					<div class="wrap_thumb">
						<img class="img_thumb" width="160" height="90" alt="게시글 대표 이미지"
							 :src="getThumbnail(post.contentSummary.representationImage, 'C320x180')"/>
					</div>
					<strong class="tit_table">{{ post.title }}</strong>
					<span class="desc_table">{{ post.contentSummary.content }}</span>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

const TABLE_DOMAIN = 'https://table.cafe.daum.net';
const LOCALE = 'ko-KR';

export default {
	props: {
		recommendTable: Object,
		totalCounts: Object,
		categories: Array
	},
	computed: {
		...mapGetters({
			tiaraObj: 'getTiaraObj'
		}),
		category() {
			return this.categories[0];
		},
		tableHomeUrl() {
			return `${TABLE_DOMAIN}/t/${this.recommendTable.tableId}`;
		},
		tableExplorerUrl() {
			return `${TABLE_DOMAIN}/tableexplore/${this.category.id}`;
		},
		posts() {
			return this.recommendTable.posts.sort(() => Math.random() - 0.5).slice(0, 4);
		}
	},
	methods: {
		getThumbnail(url, type) {
			const thumbnailHost = url.match(/(beta|sandbox)\.(daum|kakao)cdn\.net/) ? '//img1-sandbox.kakaocdn.net' : '//img1.daumcdn.net';
			return `${thumbnailHost}/thumb/${type}/?fname=${encodeURIComponent(url)}`;
		},
		getTablePostUrl(tableId, postId) {
			return `${TABLE_DOMAIN}/p/${tableId}/${postId}`;
		},
		getPostUserAction(post, index) {
			return {
				page: 'recommend_table_post',
				pageName: '추천테이블게시글목록',
				layer: 'enter_post_view',
				layerName: '게시글뷰인입버튼',
				customProps: {
					ref: 'recommend_table_post_list',
					tableId: post.tableId,
					postId: post.postId,
					ordnum: index
				}
			};
		},
		getViewImpContent({tableId, postId, title}, index) {
			return {
				id: postId,
				viewImpContent: {
					layer1: 'post_feed',
					imp_extra_info: JSON.stringify({
						tableId,
						postId,
						title,
						ordnum: index
					})
				}
			};
		},
		formatCounts(count) {
			if (isNaN(count)) {
				return '0';
			}
			if (count < 10000) {
				return count.toLocaleString(LOCALE);
			}
			if (count < 1000000) {
				return `${(Math.floor((count * 10) / 10000) / 10).toFixed(1).replace(/\.0$/, '')}만`;
			}
			if (count < 10000000) {
				return `${Math.floor(count / 10000)}만`;
			}
			return '999만+';
		}
	}
}
</script>
