var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cafe_fancafe", attrs: { id: "fanCafe" } }, [
    _c("div", { staticClass: "inner_fancafe" }, [
      _c("div", { staticClass: "wrap_tit" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "box_util" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "tiara",
                  rawName: "v-tiara",
                  value: _vm.tiaraObj.fanCafeMoreBtn,
                  expression: "tiaraObj.fanCafeMoreBtn",
                },
              ],
              staticClass: "link_all",
              attrs: { href: "/_c21_/fancafe_list" },
            },
            [_vm._v("전체보기")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list_rank_top" },
        _vm._l(_vm.fandomRankViewList, function (item) {
          return _c("fancafe-item", {
            directives: [
              {
                name: "tiara",
                rawName: "v-tiara",
                value: _vm.tiaraObj.fanCafe,
                expression: "tiaraObj.fanCafe",
              },
            ],
            attrs: { item: item },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "tit_item" }, [
      _c("span", { staticClass: "ico_cafenew ico_fan" }),
      _vm._v("팬카페"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }