var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "cafe_item",
      class: _vm.ison,
      on: { mouseover: _vm.activate },
    },
    [
      _c("a", { attrs: { href: _vm.cafe.content.readUrl, target: "_blank" } }, [
        _c("strong", { staticClass: "tit_info" }, [
          _c("span", { staticClass: "screen_out" }, [_vm._v("글제목")]),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.cafe.content.title) },
          }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "txt_name" }, [
          _c("span", { staticClass: "screen_out" }, [_vm._v("카페명")]),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.cafe.cafeName) } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }