var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "link_category link_effect",
      class: _vm.className,
      attrs: { href: _vm.href },
    },
    [
      _vm.isMain
        ? _c("strong", [_vm._v(_vm._s(_vm.replacedName))])
        : _c("span", [_vm._v(_vm._s(_vm.replacedName))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }