<template>
	<a :href="href" class="link_category link_effect" :class="className">
		<strong v-if="isMain">{{ replacedName }}</strong>
		<span v-else>{{ replacedName }}</span>
	</a>
</template>

<script>
export default {
	props: ['name', 'hash', 'isMain'],
	computed: {
		href() {
			return `/_c21_/cafe_ranking#${this.hash}`
		},
		className() {
			return { main_cate: this.isMain };
		},
		replacedName() {
			return this.name.replace('/', ' · ');
		}
	}
}
</script>
