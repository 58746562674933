import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import suggestCafe from './suggest-cafe';
import AjaxCaller from '../ajax-caller';

const PREFIX = '/_c21_/api/v3';

export const store = new Vuex.Store({
	modules: {
		suggestCafe
	},

	state: {
		recommendTables: [],
		popularlist: [],
		date: '',
		time: '',
		banner: null,
		contents: [],
		myCafes: [],
		myBoards: [],
		myCafeCount: 0,
		myBoardCount: 0,
		hasNewNews: false,
		hasNewMessage: false,
		notice: [],
		hotIssueBanner: {},
		fandomRankViewList: [],

		tiaraObj: {
			sliderBanner: { layer: 'category_story_banner', desc: '카테고리 인기글>프로모션 배너' },
			sliderArticle: {
				layer: 'category_story_title', desc: '카테고리 인기글 목록', customProps: {
					category: '',
					title: ''
				}
			},

			guestCafeSuggest: { layer: 'recommend_cafe_title', desc: '비로그인>추천카페' },
			favCafe: { layer: 'favorite_cafe_title', desc: '즐겨찾는 카페 목록' },
			myCafe: { layer: 'my_cafe_title', desc: '가입카페 목록' },
			cafeMakeBtn: { layer: 'cafe_create_btn', desc: '카페만들기 버튼' },
			userCafeSuggest: { layer: 'recommend_cafe_list', desc: '로그인>추천카페' },
			myCafeMoreBtn: { layer: 'cafe_more_btn', desc: '내카페 목록 더보기' },
			favBoard: { layer: 'favorite_board_title', desc: '즐겨찾는 게시판 목록' },
			favBoardMoreBtn: { layer: 'board_more_btn', desc: '즐겨찾는 게시판 더보기' },
			myNewsBtn: { layer: 'activity_noti_btn', desc: '내소식 버튼' },
			messageBtn: { layer: 'msg_btn', desc: '쪽지 버튼' },

			recommendTableTitle: { layer: 'recommend_table_title', desc: '추천테이블제목' },
			recommendTableCategory: { layer: 'recommend_table_category', desc: '추천테이블카테고리' },
			recommendTablePost: { layer: 'recommend_table_post', desc: '추천테이블게시글목록' },
			recommendTableNextBtn: { layer: 'recommend_table_next_btn', desc: '추천테이블다음버튼' },
			recommendTablePrevBtn: { layer: 'recommend_table_prev_btn', desc: '추천테이블이전버튼' },

			supportersNoti: { layer: 'support_noti_title', desc: '서포터즈 공지 목록' },
			supportersBanner: { layer: 'support_noti_banner', desc: '서포터즈 공지 배너' },

			banner: { layer: 'promotion_banner', desc: '프로모션 배너' },
			fanCafe: { layer: 'fan_cafe_title', desc: '팬카페 1,2,3위' },
			fanCafeMoreBtn: { layer: 'fan_cafe_more_btn', desc: '팬카페 더보기 버튼' },
			categoryRanking: { layer: 'category_rank_btn', desc: '카테고리별 랭킹' },
		}
	},

	getters: {
		getRecommendTables(state) {
			return state.recommendTables
		},
		getPopularlist(state) {
			return state.popularlist
		},
		getDate(state) {
			return state.date
		},
		getTime(state) {
			return state.time
		},
		getCategoryBanner(state) {
			return state.banner
		},
		getCategoryContents(state) {
			return state.contents
		},
		getMyCafe(state) {
			return state.myCafes
		},
		getMyBoard(state) {
			return state.myBoards
		},
		getMyCafeCount(state) {
			return state.myCafeCount
		},
		getMyBoardCount(state) {
			return state.myBoardCount
		},
		getNewNews(state) {
			return state.hasNewNews
		},
		getNewMessage(state) {
			return state.hasNewMessage
		},
		getNotice(state) {
			return state.notice
		},
		getFancafe(state) {
			return state.fandomRankViewList
		},
		getHotIssueBanner(state) {
			return state.hotIssueBanner
		},

		getTiaraObj(state) {
			return state.tiaraObj;
		},
	},
	mutations: {
		setRecommendTables(state, tables) {
			state.recommendTables = tables
		},
		setPopularlist(state, articles) {
			state.popularlist = articles
		},
		setDate(state, data) {
			state.date = data
		},
		setTime(state, data) {
			state.time = data
		},
		setCategoryBanner(state, banner) {
			state.banner = banner
		},
		setCategoryContents(state, contents) {
			state.contents = contents
		},
		setMyCafe(state, cafes) {
			state.myCafes = cafes
		},
		setMyBoard(state, boards) {
			state.myBoards = boards
		},
		setMyCafeCount(state, count) {
			state.myCafeCount = count
		},
		setMyBoardCount(state, count) {
			state.myBoardCount = count
		},
		setNewNews(state, hasnew) {
			state.hasNewNews = hasnew
		},
		setNewMessage(state, hasnew) {
			state.hasNewMessage = hasnew
		},
		setNotice(state, data) {
			state.notice = data
		},
		setHotIssueBanner(state, data) {
			state.hotIssueBanner = data
		},
		setFancafe(state, data) {
			state.fandomRankViewList = data
		},
	},
	actions: {
		async loadRecommendTables({ commit }) {
			try {
				const data = await AjaxCaller.get(`/_c21_/table/recommend-tables`);
				commit('setRecommendTables', data.recommendTables);
			} catch(e) {
				throw e;
			}
		},
		async loadPopular({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/popular/article`);
				commit('setPopularlist', data.articles);
				if(data.serviceDateTime){
					commit('setDate', data.serviceDateTime.substring(0, 11));
					commit('setTime', data.serviceDateTime.substring(11));
				}
			} catch(e) {
				throw e;
			}
		},
		async loadCategoryArticle({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/popular/category/article`);
				commit('setCategoryContents', data.contents);
				if(data.banner)
					commit('setCategoryBanner', data.banner);
			} catch(e) {
				throw e;
			}
		},
		async loadMyCafe({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/my-cafe/home/cafes`);
				commit('setMyCafe', data);
			} catch(e) {
				throw e;
			}
		},
		async loadMyBoard({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/my-cafe/favorite-boards`, {pageSize:20});
				commit('setMyBoard', data);
			} catch(e) {
				throw e;
			}
		},
		async loadMyCafeCount({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/my-cafe/cafes-count`);
				commit('setMyCafeCount', data.joinCafes);
			} catch(e) {
				throw e;
			}
		},
		async loadMyBoardCount({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/my-cafe/favorite-boards-count`);
				commit('setMyBoardCount', data.count);
			} catch(e) {
				throw e;
			}
		},
		async loadNewNews({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/my-news/total/has-new`);
				commit('setNewNews', data.hasNew);
			} catch(e) {
				throw e;
			}
		},
		async loadNewMessage({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/msgbox/total/has-new`);
				commit('setNewMessage', data.hasNew);
			} catch(e) {
				throw e;
			}
		},
		async loadNotice({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/notice`);
				commit('setNotice', data.notices);
			} catch(e) {
				throw e;
			}
		},
		async loadHotIssueBanner({ commit }) {
			try {
				const data = await AjaxCaller.get(`${PREFIX}/banner?location=HOT_ISSUE`);
				commit('setHotIssueBanner', data.banners[0]);
			} catch(e) {
				throw e;
			}
		},
		async loadFancafe({ commit }) {
			try {
				const data = await AjaxCaller.get(`/_c21_/api/fancafe/ranking/fandom/hourly?categoryName=all&count=3`);
				commit('setFancafe', data.fandomRankViewList.slice(0, 3));
			} catch(e) {
				throw e;
			}
		},
		
		async addFavoriteCafe({ commit }, cafeInfo) {
			try {
				await AjaxCaller.post(`${PREFIX}/my-cafe/favorite-cafes/${cafeInfo.grpid}`);
			} catch(e) {
				throw e;
			}
		},

		async deleteFavoriteCafe({ commit }, cafeInfo) {
			try {
				await AjaxCaller.delete(`${PREFIX}/my-cafe/favorite-cafes/${cafeInfo.grpid}`);
			} catch (e) {
				throw e;
			}
		},
		
		async deleteFavoriteBoard({ commit }, boardInfo) {
			try {
				await AjaxCaller.delete(`${PREFIX}/my-cafe/favorite-boards/${boardInfo.grpid}/${boardInfo.fldid}`);
			} catch(e) {
				throw e;
			}
		},
	}
});
