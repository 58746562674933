<template>
	<div id="mEtc">
		<div class="cafe_category">
			<div class="wrap_tit">
				<h3 class="tit_item">카테고리 <span class="txt_noraml">별 랭킹 보기</span></h3>
			</div>
			<div class="kind_category" v-for="line in categories">
				<category-item v-tiara="tiaraObj.categoryRanking" v-for="category in line"
					:name="category.name"
					:hash="category.hash"
					:is-main="category.isMain"></category-item>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CategoryItem from './CategoryItem.vue';

export default {
	components: {
		CategoryItem
	},

	data() {
		return {
			categories: [
				[
					{ name: '생활/경제', hash: 'life-economy', isMain: true },
					{ name: '생활/건강', hash: 'life', isMain: false },
					{ name: '경제/금융', hash: 'economy', isMain: false },
					{ name: '정치/사회', hash: 'society', isMain: false },
					{ name: '팬카페', hash: 'fancafe', isMain: true }
				], [
					{ name: '친목/모임', hash: 'group', isMain: true },
					{ name: '친목', hash: 'friendship', isMain: false },
					{ name: '여성', hash: 'woman', isMain: false },
					{ name: '게임/만화', hash: 'game-comics', isMain: true },
					{ name: '만화/애니메이션', hash: 'comics', isMain: false },
					{ name: '게임', hash: 'game', isMain: false }
				], [
					{ name: '취미/레저', hash: 'hobby-sport', isMain: true },
					{ name: '취미', hash: 'hobby', isMain: false },
					{ name: '스포츠/레저', hash: 'sport', isMain: false },
					{ name: '연예/예술', hash: 'entertainment', isMain: true },
					{ name: '영화', hash: 'movie', isMain: false },
					{ name: '음악', hash: 'music', isMain: false },
					{ name: '방송/연예', hash: 'broadcast', isMain: false },
					{ name: '문학/예술', hash: 'art', isMain: false }
				], [
					{ name: '지역/동문', hash: 'local', isMain: true },
					{ name: '동창/동문', hash: 'alumni', isMain: false },
					{ name: '지역/고향', hash: 'hometown', isMain: false },
					{ name: 'e-마을', hash: 'e-village', isMain: false },
					{ name: '군대', hash: 'army', isMain: false },
					{ name: '종교', hash: 'religion', isMain: false }
				], [
					{ name: '학문/교육', hash: 'education', isMain: true },
					{ name: '교육/외국어', hash: 'learning', isMain: false },
					{ name: '컴퓨터/인터넷', hash: 'computer', isMain: false },
					{ name: '과학/인문', hash: 'academic', isMain: false },
					{ name: '대학/대학원', hash: 'university', isMain: false },
					{ name: '초중고교', hash: 'school', isMain: false }
				]
			]
		};
	},
	computed: mapGetters({
		tiaraObj: 'getTiaraObj',
	}),
}
</script>

<style scoped>

</style>
