var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tables.length > 0
    ? _c(
        "div",
        { staticClass: "cafe_newtable" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.tables, function (table, i) {
            return _c(
              "RecommendTable",
              _vm._b(
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: i === _vm.currentIndex,
                      expression: "i === currentIndex",
                    },
                  ],
                  key: table.recommendTable.tableId,
                },
                "RecommendTable",
                table,
                false
              )
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "wrap_btn" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tiara",
                    rawName: "v-tiara",
                    value: _vm.tiaraObj.recommendTablePrevBtn,
                    expression: "tiaraObj.recommendTablePrevBtn",
                  },
                ],
                staticClass: "btn_navi btn_prev",
                attrs: { disabled: !_vm.hasPrev },
                on: { click: _vm.goToPrevPage },
              },
              [
                _c("span", { staticClass: "ico_cafenew" }, [
                  _vm._v("이전 보기"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tiara",
                    rawName: "v-tiara",
                    value: _vm.tiaraObj.recommendTableNextBtn,
                    expression: "tiaraObj.recommendTableNextBtn",
                  },
                ],
                staticClass: "btn_navi btn_next",
                attrs: { disabled: !_vm.hasNext },
                on: { click: _vm.goToNextPage },
              },
              [
                _c("span", { staticClass: "ico_cafenew" }, [
                  _vm._v("다음 보기"),
                ]),
              ]
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap_tit" }, [
      _c("h3", { staticClass: "tit_item" }, [_vm._v("추천 테이블")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }