<template>
	<div id="mArticle">
		<div id="home_popular" class="cafe_popular">
			<div class="wrap_tit">
				<h3 class="tit_item">카페 인기글</h3>
				<div class="box_util">
					<span class="txt_date"><span class="screen_out">날짜</span>{{date}}<em><span class="screen_out">시간</span>{{time}}</em></span>
				</div>
			</div>
			<ol class="list_popular">
				<popular-item v-tiara="tiaraObj.popularArticle" v-for="item in renderlist.slice(0, 6)" :item="item"></popular-item>
				<li id="popular-ad"></li>
				<popular-item v-tiara="tiaraObj.popularArticle" v-for="item in renderlist.slice(6, 10)" :item="item"></popular-item>
			</ol>
			<div v-if="totalCount > 1" class="wrap_paging">
				<div class="inner_paging">
					<div class="paging_info">
						<span><span class="screen_out">현재 페이지</span><em class="num_index">{{current}}</em><span class="screen_out">전체 페이지</span> &nbsp; / &nbsp; {{totalCount}}</span>
					</div>
					<button v-tiara="tiaraObj.popularPrevBtn" type="button" class="btn_item btn_prev" :class="prevBtnClass" :disabled="predisabled" @click="prelist()"><!-- 버튼 비활성시 btn_disabled 클래스 추가 및 disabled 속성 추가 -->
						<span class="ico_cafenew">이전 인기글</span>
					</button>
					<button v-tiara="tiaraObj.popularNextBtn" type="button" class="btn_item btn_next" :class="nextBtnClass" :disabled="nextdisabled" @click="nextlist()">
						<span class="ico_cafenew">다음 인기글</span>
					</button>
				</div>
			</div>
			<img v-if="renderlist.length < 7" src="//t1.daumcdn.net/cafe_image/cafetop/2019/img_popular.png" class="bg_popular" alt="">
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import PopularItem from './PopularItem.vue'
	import PopularAd from './PopularAd.vue'
	export default {
		components: {
			PopularItem,
			PopularAd,
		},
		data() {
			return {
				current: 1,
				renderlist: [],
			};
		},
		computed:{
			...mapGetters({
				popularlist: 'getPopularlist',
				date: 'getDate',
				time: 'getTime',
				tiaraObj: 'getTiaraObj',
			}),
			prevBtnClass() {
				return { btn_disabled: this.current <= 1 };
			},
			nextBtnClass() {
				return { btn_disabled: this.current >= this.totalCount };
			},
			predisabled(){
				return this.current <= 1
			},
			nextdisabled(){
				return this.current >= this.totalCount || this.totalCount === 1
			},
			totalCount(){
				const num = Math.floor(this.popularlist.length / 10) + 1
				return num > 10 ? 10 : num;
			}
		},
		methods:{
			prelist(){
				this.current--;
				this.reformData();
			},
			nextlist(){
				this.current++;
				this.reformData();
			},
			reformData(){
				this.renderlist = this.popularlist.slice(this.current * 10 - 10, this.current * 10);
				this.initAd();
			},
			initAd() {
				let adkey = 'DAN-cZdJrJdvdMHhD5ZY';
				if(IS_DEV)
					adkey = 'DAN-IqcaWN3ALugiGEJL';
				
				const html = '<div class="popular_ad" adfit-unit-id="'+ adkey +'">' +
					'<a adfit-landing-url class="link_popular">' +
					'<div class="thumb_info_type">' +
					'<div class="wrap_thumb">' +
					'<img adfit-image-width="104" adfit-image-height="65" adfit-main-img-url="" class="img_thumb" alt="">' +
					'</div>' +
					'<div class="wrap_info">' +
					'<span class="num_item"><span class="ad_bar"></span></span>' +
					'<p class="desc_info" adfit-title></p>' +
					'</div>' +
					'</div>' +
					'<div class="additional_info">' +
					'<div class="wrap_ad">' +
					'<span class="ad_name"><span class="ico_cafenew ico_ad">광고</span><span adfit-profile-name=""></span></span>' +
					'</div>' +
					'</div>' +
					'</a>' +
					'<a adfit-ad-info style="display: none;"></a>' +
					'</div>';
				this.$nextTick(function () {
						new AdfitNativeAds({unitId: adkey}).renderAs(html, function(ads) {
							$('#popular-ad').show().html(ads);
						}).renderFail(function (error) {
							$('#popular-ad').hide();
						}).init();
				})
			},
		},
		async created(){
			await this.$store.dispatch('loadPopular')
			this.reformData();
		}
	}
</script>
