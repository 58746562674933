var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "cafe_create" }, [
      _c(
        "a",
        { staticClass: "link_favorite", attrs: { href: "/_c21_/make" } },
        [
          _c("div", { staticClass: "thumb_info_type" }, [
            _c("div", { staticClass: "wrap_thumb" }, [
              _c("img", {
                staticClass: "img_thumb",
                attrs: {
                  src: "//t1.daumcdn.net/cafe_image/cafetop/2019/favorite_defalut.png",
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "wrap_info" }, [
              _c("div", { staticClass: "box_tbl" }, [
                _c("div", { staticClass: "inner_tbl" }, [
                  _c("strong", { staticClass: "tit_info" }, [
                    _vm._v("카페 만들기"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "additional_info" }, [
                    _c("span", { staticClass: "txt_item" }, [
                      _vm._v("지금 카페를 만들어 보세요!"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }