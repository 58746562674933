<template>
	<span class="num_item">
		{{item.fandomCurrRank}}
		<span v-if="rankChange > 0" class="info_change"><span class="ico_cafetop ico_up">상승</span>{{rankChange}}</span>
		<span v-if="rankChange < 0" class="info_change"><span class="ico_cafetop ico_down">하락</span>{{Math.abs(rankChange)}}</span>
		<span v-if="rankChange === 0" class="info_change"><span class="ico_cafetop ico_stay">유지</span></span>
	</span>
</template>

<script>
	export default {
		props: ['item'],
		computed: {
			rankChange() {
				return this.item.fandomPrevRank - this.item.fandomCurrRank;
			},
		}
	}
</script>
