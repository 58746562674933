<template>
	<li>
		<a :href="item.homeUrl" target="_blank" class="link_favorite">
			<div class="thumb_info_type">
				<div class="wrap_thumb"><img :src="'//img1.daumcdn.net/thumb/C151x151/?fname=' + item.thumbnail" class="img_thumb" alt=""></div>
				<div class="wrap_info">
					<div class="box_tbl">
						<div class="inner_tbl">
							<strong class="tit_info type_change_line">{{item.cafeName}}</strong>
							<p class="txt_explanation">{{item.desc}}</p>
							<div class="tag_type_info">
								<em class="txt_info txt_emph">#추천카페</em>
								<span v-for="keyword in keywords" class="txt_info">#{{ keyword }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a>
	</li>
</template>

<script>
	export default {
		props: ['item'],
		data() {
			return {
				limitLength: 26, // 반각 기준
			};
		},
		computed: {
			keywords() {
				let length = 0;

				return this.item.keywords
					.filter(v => {
						length += this.getStringBytes(v) + 2;
						return length <= this.limitLength;
					});
			},
		},

		methods: {
			getStringBytes(s) {
				return s.split('')
					.map(v => {
						if (v.match(/[A-Za-z0-9]/)) return 1;
						return 2;
					}).reduce((a, b) => a+b, 0);
			},
		},
	}
</script>
