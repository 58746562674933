var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      key: _vm.page,
      staticClass: "popular_ad",
      attrs: { "adfit-unit-id": _vm.adunit },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("a", {
        staticStyle: { display: "none" },
        attrs: { "adfit-ad-info": "" },
      }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "link_popular", attrs: { "adfit-landing-url": "" } },
      [
        _c("div", { staticClass: "thumb_info_type" }, [
          _c("div", { staticClass: "wrap_thumb" }, [
            _c("img", {
              staticClass: "img_thumb",
              attrs: {
                "adfit-image-width": "104",
                "adfit-image-height": "65",
                "adfit-main-img-url": "",
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wrap_info" }, [
            _c("span", { staticClass: "num_item" }, [
              _c("span", { staticClass: "ad_bar" }),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "desc_info", attrs: { "adfit-title": "" } }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "additional_info" }, [
          _c("div", { staticClass: "wrap_ad" }, [
            _c("span", { staticClass: "ad_name" }, [
              _c("span", { staticClass: "ico_cafenew ico_ad" }, [
                _vm._v("광고"),
              ]),
              _c("span", { attrs: { "adfit-profile-name": "" } }),
            ]),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }