<template>
	<div class="cafe_item" :class="ison" @mouseover="activate">
		<a :href="cafe.article.readUrl">
		<strong class="tit_info"><span class="screen_out">글제목</span>{{cafe.article.title.slice(0,25)}}</strong>
		<span class="txt_name"><span class="screen_out">카페명</span>{{cafe.cafe.name.slice(0,20)}}</span>
		</a>
	</div>
</template>

<script>
	export default {
		props: ['cafe', 'i', 'num'],
		computed: {
			ison(){
				return { hover_effect: this.i === this.num }
			}
		},
		methods: {
			activate(){
				this.$emit('hoverEvent', this.i)
			}
		},
	}
</script>
