<template>
	<li>
		<div class="box_slider slider_box_type2">
			<div class="text_cont">
				<recommend-best-item v-for="(cafe, i) in item.articles.slice(0, 4)" :cafe="cafe" :i="i" :num="num" @hoverEvent="changeNum"></recommend-best-item>
			</div>
			<div class="slider_profile">
				<div class="thumb_info_type">
					<div class="wrap_thumb">
						<img :src="item.articles[0].article.image" class="img_thumb" alt="">
						<!-- 19.04.16 클래스명 수정 / 태그 수정 -->
						<div class="tag_cafe_ico">
							<span class="ico_cafenew ico_great">우수카페</span>
						</div>
						<!-- 19.04.16 클래스명 수정 / 태그 수정 끝 -->
					</div>
					<div class="wrap_info">
						<div class="box_tbl">
							<div class="inner_tbl">
								<span class="txt_item">이달의 인기카페</span>
								<strong class="tit_name">{{item.articles[0].cafe.name}}</strong>
							</div>
						</div>
					</div>
				</div>
				<div class="explantion_info">
					<p class="txt_explanation">{{item.articles[0].cafe.name}}</p>
					<div class="tag_type_info">
						<span class="txt_info">#연예인</span>
						<span class="txt_info">#친목</span>
						<span class="txt_info">#유머</span>
					</div>
				</div>
			</div>
		</div>
	</li>
</template>

<script>
	import RecommendBestItem from './RecommendBestItem.vue'
	export default {
		components:{
			RecommendBestItem
		},
		props: ['item'],
		data(){
			return {
				num: -1
			}
		},
		computed: {
			randomNumber(){
				return Math.floor(Math.random() * Math.floor(3));
			}
		},
		methods: {
			changeNum(num){
				this.num = num;
			}
		},
		created(){
			this.num = this.randomNumber; 
		}
	}
</script>
