<template>
	<div class="cafe_support">
		<div class="wrap_tit">
			<h3 class="tit_item">카페 지원 안내</h3>
		</div>
		<div class="wrap_support" @mouseover="offTimer" @mouseleave="onTimer">
			<ul class="list_support">
				<li v-if="activeIndex === i" v-for="(banner, i) in supporters" :key="i">
					<a v-tiara="tiaraObj.supportersBanner" :href="banner.linkUrl" target="_blank" class="link_support">
						<img :src="banner.thumb" :alt="banner.title" class="img_thumb">
					</a>
				</li>
			</ul>
			<div class="dotted_paging">
				<button v-for="(item, index) in supporters" class="dotted_item" :class="isOn(index)"
					  @click="changeBanner(index)">{{totalBanners}}개 중 {{index + 1}}번째 배너</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	export default {
		name: "CafeSupport",
		data() {
			return {
				activeIndex: 0,
				supporters: [{
					linkUrl: 'https://cafe.daum.net/supporters/',
					thumb: '//t1.daumcdn.net/cafe_image/pc/banner/cafe_supporters_banner_3.png',
					title: '니니즈와 함께 하는 서포터즈 카페 구경하실래요?',
				}, {
					linkUrl: 'https://cafe.daum.net/supporters/MkUO/1',
					thumb: '//t1.daumcdn.net/cafe_image/pc/banner/cafe_supporters_banner_4.png',
					title: '새로워진 카페 홍보 지원 신청 방법 알아보기',
				}]
			}
		},
		computed: {
			...mapGetters({
				tiaraObj: 'getTiaraObj',
			}),
			activeBanner() {
				return this.supporters[this.activeIndex];
			},
			totalBanners() {
				return this.supporters.length;
			}
		},
		methods: {
			isOn(index) {
				return {
					on_dotted: index === this.activeIndex
				}
			},
			offTimer() {
				clearInterval(this.tick);
			},
			onTimer() {
				this.timer();
			},
			changeBanner(index) {
				this.activeIndex = index;
			},
			timer() {
				this.tick = setInterval(() => {
					this.changeBanner(this.activeIndex)
					this.activeIndex = (this.activeIndex === this.supporters.length - 1) ? 0 : ++this.activeIndex;
				}, 6000);
			}
		},
		created() {
			this.timer();
		}
	}
</script>
