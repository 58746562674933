var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "mArticle" } }, [
    _c("div", { staticClass: "cafe_popular", attrs: { id: "home_popular" } }, [
      _c("div", { staticClass: "wrap_tit" }, [
        _c("h3", { staticClass: "tit_item" }, [_vm._v("카페 인기글")]),
        _vm._v(" "),
        _c("div", { staticClass: "box_util" }, [
          _c("span", { staticClass: "txt_date" }, [
            _c("span", { staticClass: "screen_out" }, [_vm._v("날짜")]),
            _vm._v(_vm._s(_vm.date)),
            _c("em", [
              _c("span", { staticClass: "screen_out" }, [_vm._v("시간")]),
              _vm._v(_vm._s(_vm.time)),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ol",
        { staticClass: "list_popular" },
        [
          _vm._l(_vm.renderlist.slice(0, 6), function (item) {
            return _c("popular-item", {
              directives: [
                {
                  name: "tiara",
                  rawName: "v-tiara",
                  value: _vm.tiaraObj.popularArticle,
                  expression: "tiaraObj.popularArticle",
                },
              ],
              attrs: { item: item },
            })
          }),
          _vm._v(" "),
          _c("li", { attrs: { id: "popular-ad" } }),
          _vm._v(" "),
          _vm._l(_vm.renderlist.slice(6, 10), function (item) {
            return _c("popular-item", {
              directives: [
                {
                  name: "tiara",
                  rawName: "v-tiara",
                  value: _vm.tiaraObj.popularArticle,
                  expression: "tiaraObj.popularArticle",
                },
              ],
              attrs: { item: item },
            })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.totalCount > 1
        ? _c("div", { staticClass: "wrap_paging" }, [
            _c("div", { staticClass: "inner_paging" }, [
              _c("div", { staticClass: "paging_info" }, [
                _c("span", [
                  _c("span", { staticClass: "screen_out" }, [
                    _vm._v("현재 페이지"),
                  ]),
                  _c("em", { staticClass: "num_index" }, [
                    _vm._v(_vm._s(_vm.current)),
                  ]),
                  _c("span", { staticClass: "screen_out" }, [
                    _vm._v("전체 페이지"),
                  ]),
                  _vm._v("   /   " + _vm._s(_vm.totalCount)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tiara",
                      rawName: "v-tiara",
                      value: _vm.tiaraObj.popularPrevBtn,
                      expression: "tiaraObj.popularPrevBtn",
                    },
                  ],
                  staticClass: "btn_item btn_prev",
                  class: _vm.prevBtnClass,
                  attrs: { type: "button", disabled: _vm.predisabled },
                  on: {
                    click: function ($event) {
                      return _vm.prelist()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "ico_cafenew" }, [
                    _vm._v("이전 인기글"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tiara",
                      rawName: "v-tiara",
                      value: _vm.tiaraObj.popularNextBtn,
                      expression: "tiaraObj.popularNextBtn",
                    },
                  ],
                  staticClass: "btn_item btn_next",
                  class: _vm.nextBtnClass,
                  attrs: { type: "button", disabled: _vm.nextdisabled },
                  on: {
                    click: function ($event) {
                      return _vm.nextlist()
                    },
                  },
                },
                [
                  _c("span", { staticClass: "ico_cafenew" }, [
                    _vm._v("다음 인기글"),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.renderlist.length < 7
        ? _c("img", {
            staticClass: "bg_popular",
            attrs: {
              src: "//t1.daumcdn.net/cafe_image/cafetop/2019/img_popular.png",
              alt: "",
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }