<template>
	<li>
		<a @click="trackEvent" :href="item.link" target="_blank" class="link_favorite">
			<div class="thumb_info_type">
				<div class="wrap_thumb"><img :src="'//img1.daumcdn.net/thumb/C151x151/?fname=' + item.cafeImage" class="img_thumb" alt=""></div>
				<div class="wrap_info">
					<div class="box_tbl">
						<div class="inner_tbl">
							<strong class="tit_info">{{item.cafeName}}</strong>
							<div class="additional_info">
								<span class="txt_item">{{item.memberRoleName}}</span>
								<span v-if="item.recentArticle" class="txt_date">{{updatedWhen}}</span>
								<span v-if="item.recentArticle && item.recentArticle.new" class="txt_new">NEW</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</a>
		<button class="btn_favorite" :class="favorite" @click="toggleMyCafe" :aria-pressed="item.favoriteCafe ? 'true' : 'false'">
			<span class="ico_cafenew ico_favorite">즐겨찾기</span>
		</button>
	</li>
</template>

<script>
	import { mapGetters } from 'vuex';
	import moment from 'moment';
	export default {
		props: ['item', 'i'],
		computed: {
			...mapGetters({
				tiaraObj: 'getTiaraObj',
			}),
			favorite(){
				return {on_favorite: this.item.favoriteCafe}
			},
			updatedWhen() {
				return moment(this.item.recentArticle.createdAt, 'YYYY-MM-DD+-HH:mm').format('YY.MM.DD.');
			}
		},
		methods: {
			toggleMyCafe(){
				this.$emit('toggleMyCafe', this.i)
			},

			trackEvent() {
				this.$trackEvent(this.item.favoriteCafe ? this.tiaraObj.favCafe : this.tiaraObj.myCafe);
			}
		}
	}
</script>
