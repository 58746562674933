import Vue from 'vue';
import CommonComponents from './components/common/common-components';
import TiaraPlugin from './components/common/tiara-plugin';
import Home from './Home.vue';
import { store } from './store/home.js';
import $ from 'jquery';

Vue.use(CommonComponents);
Vue.use(TiaraPlugin, {
	pageName: 'pc_home',
	pageNameDesc: '피씨홈',
	userAction: {
		page: 'recommend_table_post',
		pageName: '추천테이블게시글목록'
	}
});
new Vue({
	el: '#daumContent',
	store,
	render: h => h(Home),
});

window.cafeHomeSlider = (function () {
	var timer = 6000, interval;
	var stopSliding = false;
	
	var showId = 0;
	var $sliderWrap, $slideUl, $slideLi;
	var isSliding = false;
	
	function init() {
		if($('#mFeature').find('ul li').length === 0){
			window.setTimeout(this.init, 500)
			return false;
		}
		setElements();
		setEvents();
		setAutoSliding();
	}
	
	function setElements() {
		$sliderWrap = $('#mFeature');
		$slideUl = $sliderWrap.find('ul');
		$slideLi = $sliderWrap.find('ul li');
		if ($($slideLi[0]).find('.box_slider').length > 0) {
			$('#mFeature').find('.bg_slider').show();
		}
	}

	function setEvents() {
		$sliderWrap.on('focusin', '.btn_prev, .btn_next, a', stop);
		$sliderWrap.on('focusout', '.btn_prev, .btn_next, a', start);
		$sliderWrap.on('click', '.btn_prev', function () {
			if (isSliding) {
				return false;
			}
			stop();
			showPrev();
			return false;
		});
		$sliderWrap.on('click', '.btn_next', function () {
			if (isSliding) {
				return false;
			}
			stop();
			showNext();
			return false;
		});
		$sliderWrap.on('mouseover, mouseenter', stop);
		$sliderWrap.on('mouseout, mouseleave', start);
	}

	function setAutoSliding() {
		$($slideLi[0]).show();
		startSliding();
	}
	function stop() {
		stopSliding = true;
	}

	function start() {
		stopSliding = false;
		if(interval) {
			window.clearTimeout(interval);
		}
		startSliding();
	}

	function startSliding(){
		if(interval) {
			window.clearTimeout(interval);
		}
		interval = window.setTimeout(() => {
			if(stopSliding || isSliding){
				return false;
			}
			if(interval) {
				window.clearTimeout(interval);
			}
			showNext();
			startSliding();
		}, timer)
	}

	function reset() {
		isSliding = true;

		var $currentSlide = $($slideLi[showId]);
		var $nextSlide = $($slideLi[0]);

		$currentSlide.css({opacity: '1'}).animate({opacity: '0.1'}, 500).hide();

		animateContents($nextSlide);
		$nextSlide.show().css({opacity: '0.1'}).animate({opacity: '1.0'}, 500, function(){
			isSliding = false;
			showId = 0;
		});
	}

	function showPrev() {
		isSliding = true;

		var $currentSlide = $($slideLi[showId]);
		if(showId === 0)
			showId = $slideLi.length-1;
		else
			showId -= 1;
		var $nextSlide = $($slideLi[showId]);
		
		$currentSlide.css({opacity: '1'}).animate({opacity: '0.1'}, 500).hide();
		
		animateContents($nextSlide);
		$nextSlide.show().css({opacity: '0.1'}).animate({opacity: '1.0'}, 500, function(){
			isSliding = false;
		});
	}

	function showNext() {
		isSliding = true;
		var $currentSlide = $($slideLi[showId]);
		if(showId < $slideLi.length-1)
			showId += 1;
		else
			showId = 0;
		var $nextSlide = $($slideLi[showId]);
		
		$currentSlide.css({opacity: '1'}).animate({opacity: '0.1'}, 500).hide();

		animateContents($nextSlide);
		$nextSlide.show().css({opacity: '0.1'}).animate({opacity: '1.0'}, 500, function(){
			isSliding = false;
		});

	}

	function animateContents(obj) {
		if (obj.find('.box_slider').length > 0) {
			$('#mFeature').find('.bg_slider').show();
			obj.find('div.cafe_item').css({opacity: '0.1', marginTop: '15px'}).animate({opacity: '1.0', marginTop: '3px'}, 500);
		} else {
			$('#mFeature').find('.bg_slider').hide();
		}
	}

	return {
		init: init,
		reset: reset,
	}
})();

$(function () {
	var getParameterByName = function (name, url) {
		if (!url) {
			url = window.location.href;
		}
		name = name.replace(/[\[\]]/g, "\\$&");
		var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	};

	var closeLayer = function () {
		$('body').removeClass('wrong_cafe');
	};

	$(function () {
		if (getParameterByName('ref') == 'wrongcafe') {
			$('body').addClass('wrong_cafe');
			$('#closeCafeLayerBtn').on('click', closeLayer);
		}
	});
});
$(window).on('load', function(){
	$('#cMain').find('.cafe_category .link_category').css('fontSize', '32px')
});
