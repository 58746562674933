var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "a",
      {
        staticClass: "link_favorite",
        attrs: { href: _vm.item.link, target: "_blank" },
      },
      [
        _c("div", { staticClass: "thumb_info_type" }, [
          _c("div", { staticClass: "wrap_thumb" }, [
            _c("img", {
              staticClass: "img_thumb",
              attrs: {
                src:
                  "//img1.daumcdn.net/thumb/C151x151/?fname=" +
                  _vm.item.cafeImage,
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wrap_info" }, [
            _c("div", { staticClass: "box_tbl" }, [
              _c("div", { staticClass: "inner_tbl" }, [
                _c("strong", { staticClass: "tit_info" }, [
                  _vm._v(_vm._s(_vm.item.fldName)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "additional_info" }, [
                  _c("span", { staticClass: "txt_item" }, [
                    _vm._v(_vm._s(_vm.item.cafeName)),
                  ]),
                  _vm._v(" "),
                  _vm.item.recentArticle
                    ? _c("span", { staticClass: "txt_date" }, [
                        _vm._v(_vm._s(_vm.createdWhen)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.recentArticle.new
                    ? _c("span", { staticClass: "txt_new" }, [_vm._v("NEW")])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn_favorite on_favorite",
        attrs: { "aria-pressed": "true" },
        on: { click: _vm.deleteBoard },
      },
      [
        _c("span", { staticClass: "ico_cafenew ico_favorite" }, [
          _vm._v("즐겨찾기"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }