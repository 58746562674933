var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "daumContent", role: "main" } },
    [
      _c("home-recommend"),
      _vm._v(" "),
      _c("my-cafe"),
      _vm._v(" "),
      _c("home-main"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }