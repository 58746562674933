<template>
	<div class="cafe_fancafe" id="fanCafe">
		<div class="inner_fancafe">
			<div class="wrap_tit">
				<h3 class="tit_item"><span class="ico_cafenew ico_fan"></span>팬카페</h3>
				<div class="box_util"><a v-tiara="tiaraObj.fanCafeMoreBtn" href="/_c21_/fancafe_list" class="link_all">전체보기</a></div>
			</div>
			<ul class="list_rank_top">
				<fancafe-item v-tiara="tiaraObj.fanCafe" v-for="item in fandomRankViewList" :item="item"></fancafe-item>
			</ul>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import RankUpdown from './RankUpdown.vue'
	import FancafeItem from './FancafeItem.vue'
	export default {
		components: {
			RankUpdown,
			FancafeItem
		},
		computed: {
			...mapGetters({
				fandomRankViewList: 'getFancafe',
				tiaraObj: 'getTiaraObj',
			}),
		},
		async created(){
			await this.$store.dispatch('loadFancafe')
		}
	}
</script>
