var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c("div", { staticClass: "box_slider slider_box_type2" }, [
      _c(
        "div",
        { staticClass: "text_cont" },
        _vm._l(_vm.item.articles.slice(0, 4), function (cafe, i) {
          return _c("recommend-best-item", {
            attrs: { cafe: cafe, i: i, num: _vm.num },
            on: { hoverEvent: _vm.changeNum },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "slider_profile" }, [
        _c("div", { staticClass: "thumb_info_type" }, [
          _c("div", { staticClass: "wrap_thumb" }, [
            _c("img", {
              staticClass: "img_thumb",
              attrs: { src: _vm.item.articles[0].article.image, alt: "" },
            }),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "wrap_info" }, [
            _c("div", { staticClass: "box_tbl" }, [
              _c("div", { staticClass: "inner_tbl" }, [
                _c("span", { staticClass: "txt_item" }, [
                  _vm._v("이달의 인기카페"),
                ]),
                _vm._v(" "),
                _c("strong", { staticClass: "tit_name" }, [
                  _vm._v(_vm._s(_vm.item.articles[0].cafe.name)),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "explantion_info" }, [
          _c("p", { staticClass: "txt_explanation" }, [
            _vm._v(_vm._s(_vm.item.articles[0].cafe.name)),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tag_cafe_ico" }, [
      _c("span", { staticClass: "ico_cafenew ico_great" }, [
        _vm._v("우수카페"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tag_type_info" }, [
      _c("span", { staticClass: "txt_info" }, [_vm._v("#연예인")]),
      _vm._v(" "),
      _c("span", { staticClass: "txt_info" }, [_vm._v("#친목")]),
      _vm._v(" "),
      _c("span", { staticClass: "txt_info" }, [_vm._v("#유머")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }