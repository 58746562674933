var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "mEtc" } }, [
    _c(
      "div",
      { staticClass: "cafe_category" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.categories, function (line) {
          return _c(
            "div",
            { staticClass: "kind_category" },
            _vm._l(line, function (category) {
              return _c("category-item", {
                directives: [
                  {
                    name: "tiara",
                    rawName: "v-tiara",
                    value: _vm.tiaraObj.categoryRanking,
                    expression: "tiaraObj.categoryRanking",
                  },
                ],
                attrs: {
                  name: category.name,
                  hash: category.hash,
                  "is-main": category.isMain,
                },
              })
            }),
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrap_tit" }, [
      _c("h3", { staticClass: "tit_item" }, [
        _vm._v("카테고리 "),
        _c("span", { staticClass: "txt_noraml" }, [_vm._v("별 랭킹 보기")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }