<template>
	<li>
		<div class="slider_image_wrapper">
			<div class="slider_image_type">
				<a :href="banner.link" target="_blank"><img :src="banner.image" class="img_slider" :alt="banner.content"></a>
				<!-- TODO -->
				<!--<span class="screen_out">{{banner.content}}</span>-->
				<span class="screen_out">인기글 슬라이더 배너</span>
			</div>
		</div>
	</li>
</template>

<script>
	export default {
		props: ['banner']
	}
</script>
