<template>
	<div id="cMain">
		<recommend-tables></recommend-tables>
		<div class="cafe_newbody">
			<popular-list></popular-list>
			<div id="mAside">
				<cafe-notice></cafe-notice>
				<cafe-support></cafe-support>
			</div>
		</div>
		<div v-tiara="tiaraObj.banner" v-if="hotIssueBanner && hotIssueBanner.image" class="hot_issue_banner">
			<a :href="hotIssueBanner.link" target="_blank">
				<!-- TODO -->
				<!--<img :src="hotIssueBanner.image" :alt="hotIssueBanner.content" width="1100">-->
				<img :src="hotIssueBanner.image" alt="인기글 하단 배너" width="1100">
			</a>
		</div>
		<cafe-fancafe></cafe-fancafe>
		<cafe-category></cafe-category>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import $ from 'jquery';

	import RecommendTables from './RecommendTables.vue'
	import PopularList from './PopularList.vue'
	import CafeNotice from './CafeNotice.vue'
	import CafeSupport from './CafeSupport.vue'
	import CafeFancafe from './CafeFancafe.vue'
	import CafeCategory from './CafeCategory.vue'

	export default {
		components: {
			RecommendTables,
			PopularList,
			CafeNotice,
			CafeSupport,
			CafeFancafe,
			CafeCategory,
		},
		computed: {
			...mapGetters({
				hotIssueBanner: 'getHotIssueBanner',
				tiaraObj: 'getTiaraObj',
			}),
		},
		async created(){
			await this.$store.dispatch('loadHotIssueBanner');
		}
	}
</script>
